<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <div class="mb-2 d-flex justify-space-between">
          <h2>Доступ к проекту</h2>
          <v-btn
            color="primary"
            large
            elevation="10"
            @click="$refs.modal.show()"
          >
            <v-icon class="mr-1" size="18">mdi-plus</v-icon>
            Добавить
          </v-btn>
        </div>
        <v-data-table
          class="custom-table"
          :headers="ACCESS_TABLE_HEADER"
          :items="project['allowed_users']"
          :loading="isLoading"
          :mobile-breakpoint="0"
          hide-default-footer
          disable-sort
        >
          <template #item.actions="{ item }">
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-2"
                  size="17"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteAccess(item)"
                >
                  mdi-link-off
                </v-icon>
              </template>
              <span>Отвязать</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <ModalAccess ref="modal" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ACCESS_TABLE_HEADER from '../constants/ACCESS_TABLE_HEADER'
import ModalAccess from './ModalAccess'

export default {
  name: 'AccessBlock',
  components: { ModalAccess },
  data: () => ({
    ACCESS_TABLE_HEADER,
    isLoading: false,
  }),
  computed: {
    ...mapState('projects', ['project']),
    ...mapState('users', ['users']),
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    ...mapActions('users', ['fetch']),
    ...mapActions('projects', ['fetchProject', 'delUsers']),
    async deleteAccess({ email }) {
      if (confirm('Удалить доступ?')) {
        this.isLoading = true
        await this.delUsers({
          id: this.project.id,
          emails: [{ email }],
        })
        await this.fetchProject(this.project.id)
        this.isLoading = false
      }
    },
  },
}
</script>

<style scoped></style>
