<template>
  <v-dialog v-model="dialog" width="600">
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">Привязка наборов</v-card-title>
          <v-card-text>
            <v-select
              v-model="selected"
              :items="publishedSetsOnly"
              :menu-props="{ maxHeight: '400', maxWidth: '800' }"
              :rules="[!!selected.length || 'Выберите набор']"
              :disabled="isLoading"
              item-text="name"
              item-value="id"
              label="Выберите наборы"
              multiple
              persistent-hint
            />
          </v-card-text>
          <SaveCloseButtons :disabled="isLoading" @close="dialog = false" />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import SaveCloseButtons from '@/components/SaveCloseButtons'

export default {
  name: 'ModalSet',
  components: { SaveCloseButtons },
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: true,
    selected: [],
  }),
  computed: {
    ...mapState('projects', ['project']),
    ...mapGetters('eirSets', ['publishedSetsOnly']),
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
  },
  methods: {
    ...mapActions('eirSets', ['fetchEirSets']),
    ...mapActions('projects', ['addEirSet', 'fetchProject']),

    async show() {
      this.dialog = true
      this.isLoading = true
      await this.fetchEirSets({ filters: { unattached: true } })
      this.isLoading = false
    },

    async submit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.isLoading = true

        await this.addEirSet({
          id: this.project.id,
          eirSetsIds: this.selected.map((id) => ({ id })),
        })
        await this.fetchProject(this.project.id)

        this.isLoading = false
        this.dialog = false
      }
    },
  },
}
</script>
