<template>
  <v-dialog v-model="dialog" width="476">
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <v-card>
        <v-progress-linear v-if="isLoading" indeterminate color="primary" />

        <div class="pa-4 pt-1">
          <v-card-title class="pb-4">Добавить доступ</v-card-title>
          <v-card-text>
            <v-combobox
              v-model.trim="email"
              label="Введите электронную почту"
              :items="searchResult"
              :loading="searchLoading"
              :search-input.sync="sync"
              :rules="[
                !!email || 'Введите email',
                searchResult.includes(email) ||
                  'Введённый email не принадлежит организации',
              ]"
              hide-no-data
              no-filter
              outlined
              dense
            />
            <div class="mt-4">*&nbsp;&mdash; обязательно</div>
          </v-card-text>
          <SaveCloseButtons :disabled="isLoading" @close="dialog = false" />
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SaveCloseButtons from '@/components/SaveCloseButtons'

export default {
  name: 'ModalAccess',
  components: { SaveCloseButtons },
  data: () => ({
    valid: true,
    dialog: false,
    isLoading: false,
    searchLoading: false,
    email: '',
    sync: '',
  }),
  computed: {
    ...mapState('projects', ['project']),
    ...mapState('users', ['users']),
    searchResult: (vm) => {
      const currentUsers =
        vm.project?.allowed_users?.map(({ email }) => email) ?? []
      return vm.users
        .map(({ email }) => email)
        .filter((email) => !currentUsers.includes(email))
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.$refs.form.reset()
      }
    },
    async sync(email) {
      if (!email) return
      this.searchLoading = true
      await this.fetch()
      this.searchLoading = false
    },
  },
  methods: {
    ...mapActions('users', ['fetch']),
    ...mapActions('projects', ['addUsers']),
    ...mapActions('projects', ['fetchProject']),

    async show() {
      this.dialog = true
    },

    async submit() {
      this.$refs.form.validate()

      if (this.valid) {
        this.isLoading = true

        await this.addUsers({
          id: this.project.id,
          emails: [{ email: this.email }],
        })
        await this.$logActionEvent('Приглашен пользователь в проект')
        await this.fetchProject(this.project.id)

        this.isLoading = false
        this.dialog = false
      }
    },
  },
}
</script>
