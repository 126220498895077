<template>
  <div v-if="!isLoading">
    <Back :to="`/projects/${project.id}`">Назад</Back>
    <h1 class="mb-4">Настройки проекта «{{ project.name }}»</h1>
    <div class="row">
      <div class="col-lg-6 mb-4">
        <Sets />
      </div>
      <div class="col-lg-6 mb-4">
        <Access />
      </div>
    </div>
  </div>
  <v-progress-linear v-else class="loader" color="primary" indeterminate />
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Back from '@/components/Back'
import Sets from './partials/SetsBlock'
import Access from './partials/AccessBlock'

export default {
  name: 'Settings',
  components: { Back, Sets, Access },
  data: () => ({
    isLoading: true,
  }),
  computed: {
    ...mapState('projects', ['project']),
  },
  async mounted() {
    this.isLoading = true
    await this.fetchProject(this.$route.params.projectId)
    this.isLoading = false
  },
  methods: {
    ...mapActions('projects', ['fetchProject']),
  },
}
</script>
